<template>
  <div class="ccsqdTpl">
   

    <el-row>
      <el-col :span="6" v-for="(item, index) in list" :key="index" :offset="index > 0 ? 2 : 0">
        <el-card :body-style="{ padding: '0px' ,width:'150px',height:'150px'}" >
          <img :src="item.logo" class="image" @click.prevent="slClick(item)" />
          <!-- <div style="padding: 14px;">
            <span class="title">{{item.title}}</span>
        
          </div> -->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "TmcLogin",
  data() {
    return {
      list: [
        // {
        //   title: "空港嘉华",
        //   logo: require("@/assets/image/oa/kgjh_logo.png"),
        //   key: "kgjh",
        //   val: 1,
        // },
        // {
        //   title: "携程商旅",
        //   logo: require("@/assets/image/oa/xcsl_logo.png"),
        //   key: "xcsl",
        //   val: 2,
        // },
      ],
      urlQueryObj: {},
    };
  },
  mounted() {
    // 获取完整的URL
    this.urlQueryObj = this.$common.localget('tmcLoginInfo') || {};
    this.slClick()
  },
  methods: {
    // 点击
    /**
     * 
     *     接口：
          1.0.0/tbea/sso

          @ApiModelProperty(value = "1-空港 2-携程")
          private String channel;
          
          @ApiModelProperty(value = "1-PC 2-H5")
          private String type;
          
          
          @ApiModelProperty(value = "特变参数")
          private String ticket;
     * 
     * **/
    slClick() {
        if(!this.urlQueryObj.ticket){

          this.$message.error('非法的单点方式！')
          return 
        }
      let queryObj = {
        channel: this.urlQueryObj.channel, //1-空港 2-携程
        type: 1, // 1-PC 2-H5
        ticket: this.urlQueryObj.ticket, // 特变参数
      };
      
      this.$conn.getConn('user.tbeaSso',{jsfile:false})(queryObj,(res)=>{
        let data = res.data || "";
        
          if (data) {
            // 空港嘉华
            if(this.urlQueryObj.channel == 1){
              window.location.replace(data);
              return 
            }
            
            

            var el = document.getElementById('tmc_login_url');
						if (el) {
							el.parentNode.removeChild(el);
							el = null;
						}
						var div = document.createElement('div');
						div.setAttribute('id', 'tmc_login_url');
						document.body.appendChild(div);

            div.innerHTML = data;
						var forms = document.querySelectorAll("#tmc_login_url  form");
						forms[0].submit();

          } else {
            let tips = '未获取有效的单点链接';
            this.$message.error(tips)
      
          }
				},(err)=>{
					this.$message.error(err.tips)
				})
      
  
    },
  },
};
</script>

<style scoped>
.ccsqdTpl {
  width: 750px;
  margin: 0 auto;
  font-size: 14px;
  padding: 20px;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    height: 100%;
    /* display: block; */
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .title{
    font-weight: 600;
  }
</style>
